import raMessage from 'ra-language-english';

const { ra } = raMessage;
const message = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '0',
        toggle_hidden: '0',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Not found',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Do you want to delete %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Awaiting Approval',
    usermenu: {
      userNotice: 'Notification',
      terms: 'Terms of Use',
      manual: 'Manual',
      downloads: 'Download',
    },
    breadcrumbs: {
      details: 'Details',
      retrofitEdit: 'Details',
      taskCreate: 'Create Task',
      taskEdit: 'Task Details',
      geofenceCreate: 'Create',
      geofenceEdit: 'Edit',
      geofenceAlertList: 'Geofence Alert',
      geofenceAlertShow: 'Details',
      compactionWorkAreaCreate: 'Add Compaction Work Areas',
      compactionWorkAreaShow: 'Compaction Work Areas',
      compactionWorkAreaEdit: 'Edit Compaction Work Areas',
      compactionLayerCreate: 'Create Compaction Layers',
      compactionLayerEdit: 'Edit Compaction Layers',
      supportMode: 'Support Mode',
      retrofitManage: 'Controller Registration',
      retrofitManageAdmin: 'Controller Registration(Admin)',
      retrofitNamePlateFiles: 'Controller Registration',
      retrofitAlternateRegistCreate: 'Controller Proxy Registration',
      retrofitAlternateRegistEdit: 'Details',
      retrofitManageAdminBulkActionCreate: 'Registration',
      terms: 'TERMS OF USE',
      downloads: 'Download',
    },
    pages: {
      siteList: 'Jobsite List',
      siteRetrofitCreate: 'Add Machine to Jobsite',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Add Bucket to Jobsite',
      siteBucketEdit: 'Bucket Details',
      siteProjectEdit: 'Project Details',
      siteProjectCreate: 'Project File Registration',
      siteExtensionarmCreate: 'Add Extension Arm to Jobsite',
      taskCreate: 'Create Task',
      taskEdit: 'Task Details',
      geofenceAlertList: 'Geofence Alert',
      geofenceAlertShow: 'Geofence Alert Details',
      geofenceCreate: 'Create Geofence',
      geofenceEdit: 'Edit Geofence',
      compactionWorkAreaCreate: 'Add Compaction Work Areas',
      compactionLayerCreate: 'Create Compaction Layers',
      compactionLayerEdit: 'Edit Compaction Layers',
      siteFileEdit: 'Details',
      siteFileCreate: 'Add File to Jobsite',
      retrofitList: 'Machine List',
      retrofitEdit: '',
      retrofitRentalCreate: 'Assignment Registration',
      retrofitTransferCreate: 'Transfer Registration',
      retrofitSendBack: 'Send Back Registration',
      bucketList: 'Bucket List',
      bucketEdit: 'Bucket Details',
      bucketCreate: 'Bucket Registration',
      bucketRentalCreate: 'Bucket Sharing',
      extensionarmList: 'Extension Arm List',
      extensionarmEdit: 'Extension Arm Details',
      extensionarmShare: 'Extension Arm Sharing',
      retrofitBulkActionList: 'Bulk Registration List',
      retrofitBulkActionCreate: 'Bulk Registration',
      retrofitBasicInfoList: 'Controller List',
      retrofitBasicInfoCreate: 'Controller Registration',
      retrofitBasicInfoEdit: 'Controller Update',
      gnssSettingList: 'Ntrip Setting List',
      gnssSettingCreate: 'Create Ntrip Setting',
      gnssSettingAdminCreate: 'Create Ntrip Setting',
      gnssSettingOwnerCreate: 'Create Ntrip Setting',
      gnssSettingEdit: 'Edit Ntrip Setting',
      gnssSettingAdminEdit: 'Edit Ntrip Setting',
      gnssSettingOwnerEdit: 'Edit Ntrip Setting',
      approvalList: 'Machine List',
      retrofitRentalEdit: 'Assignment Details',
      retrofitTransferEdit: 'Transfer Details',
      asbuiltsCreate: 'Acquire Construction Result',
      asbuiltsShow: 'Asbuilt History',
      retrofitConfigList: 'Configuration Info',
      retrofitKitInfoList: 'Controller Info',
      retrofitAccuraciesList: 'Accuracy Check Result',
      retrofitAccuraciesShow: 'Details',
      retrofitCalibaList: 'Calibration Info',
      retrofitCalibInfo: 'Machine Calibration Details',
      retrofitBasicSettingList: 'Basic Setting Info',
      retrofitErrorList: 'Error Info',
      retrofitErrorShow: 'Details',
      retrofitTopographicSurveyList: 'Topographic Survey List',
      retrofitTopographicSurveyShow: 'Topographic survey Details',
      retrofitShareFileList: 'Application Log',
      retrofitCorporationLicenseList: 'License List',
      retrofitCorporationLicenseEdit: 'License Details',
      CompactionLayerList: 'Compaction Layers',
      userNoticeList: 'Notification List',
      userNoticeCreate: 'Add Notification',
      userNoticeEdit: 'Update Notification',
      userNoticeDrawer: 'Notification',
      retrofitAlternateRegists: 'Controller Proxy Registration List',
      firmwareList: 'Firmware List',
      firmwareShow: 'Firmware Details',
      firmwareCreate: 'Add Firmware',
      retrofitManage: 'Text',
      retrofitNamePlateFiles: 'Photo',
      retrofitManageAdminBulkAction: 'Bulk Registration',
      retrofitManageAdminBulkActionCreate: 'Bulk Registration',
      retrofitAlternateRegistCreate: 'Controller Proxy Registration',
      retrofitAlternateRegistEdit: 'Controller Proxy Registration Details',
      retrofitAlternateRegistCreateText: 'Text',
      retrofitAlternateRegistCreatePhoto: 'Photo',
      ntripList: 'Ntrip Info',
      ntripEdit: 'Ntrip Management',
    },
    label: {
      login: 'Login',
      selected: 'Selected (%{length} cases)',
      listNoResults: 'Data not found',
      maintenance: {
        title: 'Currently undergoing maintenance.',
        message: `We have temporarily suspended services due to maintenance.
      We apologize for the inconvenience, but please wait a moment longer.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder: 'Drop or select the file(s) (%{fileTypes}) to upload.',
        maxFileNum:
          'The maximum number of controllers that can be registered at once is five.',
      },
      nosetting: 'No Setting',
      siteRetrofitList: {
        remoteSupport: 'Remote Support',
      },
      retrofitEdit: {
        breadcrumbs: 'Details',
        belongsDetails: '',
        ntripSettingInfo: 'Ntrip Setting Info',
        machineryDetails: 'Machine',
        retrofitDetails: 'Controller',
        basicInfo: 'Basic Info',
        controller: 'Controller',
        reciverMain: 'GNSS Main Receiver',
        reciverSub: 'GNSS Sub Receiver',
        retrofitstatus: 'Controller Status',
        license: 'License',
      },
      retrofitRental: {
        rentalDetails: 'Assignment Details',
        rentalInfo: 'Assignment Details',
        lending: 'Assignment',
        return: 'Return',
        lendingDestination: 'Assigned Company',
        lendingCompany: 'Company Name',
        lendingEmail: 'Email',
        companyName: 'Company Name',
      },
      retorfitTransfer: {
        tab: 'Transfer',
        transferInfo: 'Transfer Details',
        transferButton: 'Transfer',
        transferEditButton: 'Edit Transfer',
      },
      siteConfigureEdit: {
        onButton: 'ON',
        offButton: 'OFF',
      },
      approvals: {
        rentalTab: 'Assignment',
        transferTab: 'Transfer',
      },
      buckets: {
        bucketTab: 'Bucket',
        extensionarmTab: 'Extension Arm',
        extensionarmFile: 'Extension Arm File',
        shareTo: 'Share To',
      },
      bucketRental: {
        corporation: 'Share To',
        bucketType: 'Type',
        bk3File: 'BK3 File',
      },
      retrofitBasic: {
        basicInfoTab: 'Basic Info',
        bulkActionTab: 'Bulk Registration',
        ntripTab: 'Ntrip Info',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Send Back',
        initialize: 'Initialization',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Bulk Update',
        retrofitDistributor: 'Distributor',
        retrofitRegion: 'Region',
        corporationId: 'Company ID',
        corporationName: 'Company Name',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'Company ID in Use',
        corporationName: 'Company in Use',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Company Name',
      },
      terms: {
        title: 'Terms of Use',
        message: 'Please click on the link below to review the Terms of Use.',
        confirmMessage1:
          'In order to use the Smart Construction Pilot, you must agree to the following document(s).',
        confirmMessage2:
          'If you do not agree, you will not be able to use Smart Construction Pilot.',
        select: 'Please select your region.',
      },
      downloads: {
        title: 'Project File Converter',
        message:
          'This is a stand-alone application that creates a project file from design files.',
        button: 'Download',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Automatic Numbering',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Download',
        resultFileDownload: 'Download',
      },
      tasks: {
        coordinate: 'Coordinates (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Block Size (%{unit})',
      },
      compactionLayers: {
        thickness: 'Thickness (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Details',
        rentalDetail: 'Assignment Details',
      },
      pendingTransfers: {
        breadcrumbs: 'Details',
        transferDetail: 'Transfer Details',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Details',
        alternateRegistDetail: 'Controller Proxy Registration Details',
      },
      supportMode: {
        retrofit: 'Machine',
        optional: 'Optional',
      },
      retrofitList: {
        assignment: 'Assignment',
        license: 'License',
      },
      userNotices: {
        languageDetails: 'Notification Contents',
        language: 'Language',
        languageEn: 'English',
        languageJa: 'Japanese',
        languageFr: 'French',
        languageDe: 'German',
        languageEs: 'Spanish',
        Information: 'Notification',
        Maintenance: 'Maintenance',
        emergency: 'Emergency',
        important: 'Important',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Controller Registration',
        corporationName: 'Company Name',
        basicInfoSerialNumber: 'Serial Number',
        confirmInput: 'Re-enter Serial Number',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Serial Number',
        status: 'Status',
        imageFile: 'Image',
        message1:
          'Please take a picture of the controller serial number or QR code which is clear and unblurred.',
        message2: 'Images must be less than %{mbyte}MB in size.',
        errormessage:
          'The maximum number of controllers that can be registered at once is five.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Valid',
          disable: 'Invalid',
          unLicense: 'Unallocated',
        },
        machine: 'Machine',
        before: 'Before',
        after: 'After',
      },
      geofences: {
        project: 'Project',
        projectSourceLayer: 'Layer',
        rgb: 'Color(RGB)',
        select: 'Selection geofence',
        coordinates: 'Coordinates',
        coordinatesLabel: 'Coordinates (N,E)',
        unitLength: 'Unit:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Coordinates (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Radius',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Controller Proxy Registration',
        corporationName: 'Company Name',
        email: 'Email',
        basicInfoSerialNumber: 'Serial Number',
        confirmInput: 'Re-enter Serial Number',
      },
      ntripList: {
        unusedTotal: 'Unused Total',
      },
      calibrationDetail: {
        breadcrumbs: 'Details',
        machineInfo: 'Machine Info',
        machineGeometryInfo: 'Machine Geometry Info',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'IMU Mount Info',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Main GNSS Mount Info',
        bodyCenterToGnssSub: 'Sub GNSS Mount Info',
        extendedModel: 'Extended Model',
        measuredValues: 'Calibration Info',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Data conversion error',
        system_localization_quantity_error: `Multiple files containing localization information cannot be registered.
      Please register only one file that contains localization information.`,
        system_detect_0byte_file_error: `File registration to the server failed due to a communication error.
      Please register the file again.`,
        system_batch_error: 'System Error (P001)',
        system_parameter_error: 'System Error (P002)',
        system_parameterfile_notfound: 'System Error (P003)',
        system_exception: 'System Error (P004)',
        system_logfile_error: 'System Error (P005)',
        system_parameterfile_parameter_error: 'System Error (P006)',
        desing_localization_error: `There is no localization information available.
      Please register one of the following files:

      ・GC3 file
      ・TP3 file containing control points
      ・CAL file`,
        desing_no_support_dxf_error:
          'The DXF file(s) could not be read because its version is older than AutoCAD2000.',
        desing_tile_error: 'Failed to create project file.',
        desing_outline_error: 'Outer boundary line creation failed.',
        desing_area_length_error:
          'The process has been aborted because the project area is too large. Please check if the design data and the scale of the CAD data is correct.',
        desing_layer_name_duplication_error: `Project data with duplicate layer names cannot be registered.
      Please change the design layer name or register so that there is only one file with the same design layer name.`,
        targetFiles: '[File Name]',
        targetLayers: '[Layer]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'None',
        system_batch_error: 'System Error (T001)',
        system_parameter_error: 'System Error (T002)',
        system_parameterfile_notfound: 'System Error (T003)',
        system_exception: 'System Error (T004)',
        system_logfile_error: 'System Error (T005)',
        system_basefile_notfound: 'System Error (T006)',
        system_prjfile_multi: 'System Error (T007)',
        system_basefile_read_error: 'System Error (T008)',
        system_target_layer_notfound: 'System Error (T009)',
        system_target_layer_duplication: 'Same layer name already exists.',
        system_taskarea_error: 'Invalid task area.',
        system_taskarea_design_notfound:
          'There is no data for project file within the task area(TIN, linework, points).',
        system_tile_error: 'Failed to create project file.',
        system_outline_error: 'Outer boundary line creation failed',
        system_parameterfile_error: 'System Error',
      },
      retrofitError: {
        1000: 'UDP data from Controller not detected',
        2000: 'Body IMU Undetected',
        2001: 'Body IMU Hardware error',
        2002: 'Body IMU Algorithm error',
        2003: 'Body IMU Data error',
        2004: 'Body IMU Initialization error',
        2100: 'Boom IMU Undetected',
        2101: 'Boom IMU Hardware error',
        2102: 'Boom IMU Algorithm error',
        2103: 'Boom IMU Data error',
        2104: 'Boom IMU Initialization error',
        2200: 'Arm IMU Undetected',
        2201: 'Arm IMU Hardware error',
        2202: 'Arm IMU Algorithm error',
        2203: 'Arm IMU Data error',
        2204: 'Arm IMU Initialization error',
        2300: 'Bucket IMU Undetected',
        2301: 'Bucket IMU Hardware error',
        2302: 'Bucket IMU Algorithm error',
        2303: 'Bucket IMU Data error',
        2304: 'Bucket IMU Initialization error',
        2400: 'Tilt bucket IMU Undetected',
        2401: 'Tilt bucket IMU Hardware error',
        2402: 'Tilt bucket IMU Algorithm error',
        2403: 'Tilt bucket IMU Data error',
        2404: 'Tilt bucket IMU Initialization error',
        2500: '2nd Boom IMU Undetected',
        2501: '2nd Boom IMU Hardware error',
        2502: '2nd Boom IMU Algorithm error',
        2503: '2nd Boom IMU Data error',
        2504: '2nd Boom IMU Initialization error',
        2600: 'Swing Sensor Undetected',
        2601: 'Swing Sensor System error',
        2602: 'Swing Sensor Hardware error',
        2700: 'Offset Sensor Undetected',
        2701: 'Offset Sensor System error',
        2702: 'Offset Sensor Hardware error',
        3000: 'Head side pressure sensor undetected',
        3100: 'Bottom side pressure sensor undetected',
        4000: 'GNSS antenna undetected',
        5000: 'Correction Information undetected',
        5001: 'Radio receiver signal undetected',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'You will be redirected to another website. Is it OK?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Bucket',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'Company ID and Company Name are required.',
        },
        save: {
          message1: 'Company ID/Name cannot be changed because it includes',
          message2: 'machine(s) assigned to another Company.',
          message3: '',
          message4: 'Please check the serial number(s) below.',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Controller',
        },
        undeletable: {
          message: 'The controller is in use and cannot be deleted.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Do you want to stop this Ntrip Setting?',
          delete: 'Do you want to delete the Ntrip Setting?',
        },
      },
      siteRetrofitList: {
        title: 'Supplementary Information',
        message: 'The construction period has ended.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Common Setting',
          siteSetting: 'Jobsite Settings',
        },
        mapBackgroundColor: 'Map Background Color',
        message:
          'The geofence contact detection/alert notification function may not function properly depending on the environment and conditions in which it is used. Do not overconfidently use the function, and be sure you understand the function and conditions of use.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Do you want to delete this geofence?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'The machine is linked to a jobsite and cannot be assigned. Please disconnect the machine from the jobsite.',
          message2: 'Please disconnect the machine from the jobsite.',
        },
        sameOwner: {
          message:
            'The machine cannot be assigned because your company is the owner.',
        },
        unableTransfer: {
          message:
            'The machine is awaiting transfer approval and cannot be registered the assignment.',
        },
        unableAlternateRegist: {
          message:
            'The machine is awaiting Controller Proxy Registration Approval and cannot be registered for assignment.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'The machine is linked to a jobsite cannot be transferred. Please disconnect the machine from the jobsite.',
        messageRentaled: 'The machine is assigned and cannot be transferred.',
        messageLicense: 'The machine has a license and cannot be transferred.',
        pendingApprovals: {
          message1:
            'The transfer request has been received. Please wait for the approval.',
          message2:
            'If you have any questions, please contact us from the URL below.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Do you want to send back the controller and inherit the settings to the target controller?',
        deleteNtrip: 'Do you want to initialize the returned controller?',
        unableRegion:
          'This controller has been shipped overseas. If this controller is the correct, please reset the "Region" to null.',
        unableCorporation: {
          message1: 'The controller cannot be send back.',
          message2:
            'If you believe this is an error, please contact your dealer.',
        },
        retrofitRentaled: 'The controller is assigned and cannot be send back.',
        tiedToSite:
          'The machine is linked to a jobsite and cannot be send back.',
      },
      retrofitAddToSiteAction: {
        title: 'Supplementary Information',
        message:
          'There is a machine waiting for approval, so it cannot be linked to the jobsite.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Supplementary Information',
        message:
          'The machine is being transferred and cannot be linked to the jobsite.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            'The selected Ntrip Setting is being sent to another machine.',
        },
        unableTransferInfo: {
          message:
            'In order to transfer, please cancel the following settings.',
          reasonSiteId: 'Jobsite',
          reasonRentaled: 'Assignment',
          reasonLicense: 'License',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'The assigned company is currently assigning the machine to another company. Assignment start date to another company is %{date}.',
          message2: 'Please select an end date after %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'The machine is linked to a jobsite at the assigned company. Do you want to end this assignment?',
        },
        confirmDoubleRental: {
          message:
            'The assigned company is currently assigning the machine to another company. \nThe assigned company is currently assigning the machine to another company. Setting an end date also ends the assignment to another company. Do you want to set the end date?',
        },
        unapprovedSave: {
          message: 'This assignment has not been approved and cannot be saved.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Do you want to delete this assignment?',
        },
        approvedDelete: {
          title: '',
          message: 'This assignment has been approved and cannot be deleted.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message:
            'The machine is currently on assignment and cannot be transferred.',
        },
        approvedSave: {
          message: 'The machine has a license and cannot be transferred.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Do you want to delete this transfer?',
        },
        approvedDelete: {
          title: '',
          message: 'This transfer has been approved and cannot be deleted.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Extension Arm',
        },
      },
      projectFileUpload: {
        message:
          'Multiple control point files(.gc3/.cal) cannot be registered.',
      },
      projectEdit: {
        confirm: {
          message:
            'Once updating the project will delete the existing task(s). Do you want to update?',
        },
      },
      projectConversionStatus: {
        title: 'Conversion Status',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Conversion Status',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message: 'The selected task cannot be deleted because it is in use.',
        },
        unableToCreate: {
          message: 'No design data has been registered in the project.',
        },
        unableToDelete: {
          message1:
            'The task cannot be deleted because the status of the project and the status of the task are as follows.',
          message2: 'Project: Converting',
          message3: 'Task   : Error',
        },
        unableToUpdate: {
          message1:
            'The task cannot be edited because the status of the project and the status of the task are not as follows.',
          message2: 'Project: [Converted]',
          message3: 'Task   : [Transferring][Transferred][Failed To Transfer]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Task',
        },
        unableToDelete: {
          message: 'The selected task cannot be deleted because it is in use.',
        },
      },
      taskMap: {
        crossingMessage: 'The task area is intersected.',
        sameValueMessage: 'The same coordinates have been entered.',
        areaZeroMessage: 'There is no area for the task area.',
        noPolygonMessage:
          'The task cannot be registered because it is not yet registered.',
      },
      compactionMaterials: {
        add: {
          title: 'Add a Material',
        },
        update: {
          title: 'Update a Material',
        },
      },
      weathers: {
        add: {
          title: 'Add a Weather',
        },
        update: {
          title: 'Update a Weather',
        },
      },
      operators: {
        add: {
          title: 'Add a Operator',
        },
        update: {
          title: 'Update a Operator',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Edit Compaction Work Areas',
        },
        undeletable: {
          message:
            'The compaction layer included in the construction area is in use and cannot be deleted',
        },
      },
      compactionLayers: {
        undeletable: {
          message: 'The compaction layer is in use and cannot be deleted',
        },
        deleteConfirm: {
          messageTargetItem: 'Compaction Layer',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message:
            'Another Ntrip Setting is being sent to the selected machine.',
        },
        confirmDelete: {
          message: 'Do you want to delete the Ntrip Setting?',
        },
        confirmDeleteWithMachine: {
          message:
            'The machine is currently linked. Do you want to delete the Ntrip Setting?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Notification',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Set the license(s) to the following machine.',
        },
        changeDisable: {
          message:
            'Please change the license ID of the following construction machinery to valid.',
        },
        changeTemporary: {
          message:
            'Set the license(s) to the following machine. The currently set temporary license will be invalided.',
        },
        errorAdd: {
          message:
            'The license of the machine cannot be changed because All license has already been set.',
        },
        errorSameType: {
          message:
            'The license of the machine cannot be changed because the same type of license has already been set.',
        },
        errorUnlimited: {
          message:
            'The machine has already set Unlimited license, other licenses cannot be set.',
        },
        addAll: {
          message:
            'The following machine has already been set license(s). Do you want to set All license?',
        },
        compare: {
          message: 'Change the license(s) for the following machine.',
        },
        warning: {
          message:
            'The %{retrofit} has license of %{id}. Do you want to disconnect the license?',
        },
        unableTransfer: {
          message:
            'The machine is being transferred so that the license cannot be linked.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial:
            'System Error. There is data that failed to analyze the image.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'The registration request has been received. Please wait until it is approved.',
          message2:
            'If you have any questions, please contact us from the URL below.',
        },
        corporationIdCheck: {
          message1:
            'The controller has already been registered. Please check by searching in the machines.',
          message2:
            'If you cannot find the target controller in the list, please contact us from the URL below.',
        },
        earthbrainIdCheck: {
          message1: 'We apologize for the inconvenience,',
          message2:
            'but please contact us from the URL below with the following information.',
          message3: '-Controller serial number',
          message4: '-Name of the machine to be registered (optional)',
          message5: '-Company ID to be registered',
        },
        invalidSerialNumber: {
          message1: 'The controller is already in use by another company.',
          message2: 'If you would like to change it for other reasons, ',
          message3:
            'please contact us from the URL below with the following information.',
          message4: '-Controller serial number',
          message5: '-Name of the machine to be registered (optional)',
          message6: '-Company ID to be registered',
          message7: '-Reason for change',
        },
        lendingRetrofits: {
          message1: 'The controller is assigned.',
          message2:
            'Please reapply after the assignment ends at the assigning company.',
          message3:
            'If you have any questions, please contact us from the URL below.',
        },
        siteAttached: {
          message1: 'The controller is linked to a jobsite.',
          message2:
            'Please reapply after disconnect the machine from the jobsite.',
          message3:
            'If you have any questions, please contact us from the URL below.',
        },
        serialNumberExistError: {
          message1:
            'This is the serial number of a controller that does not exist. Please make sure the controller serial number you entered is correct.',
          message2:
            'If the problem persists, please contact us from the URL below.',
        },
        ntripShortage: {
          message1: 'We were unable to issue your Ntrip user ID and password.',
          message2: 'Please contact us at the URL below.',
        },
        serialnumberCheck: {
          message:
            'Please check the products with the following serial number(s)',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'Email is required.',
        },
        pendingApprovals: {
          message1:
            'Registration request has been received. Please wait until it is approved.',
          message2:
            'If you have any questions, please contact us from the URL below.',
        },
        corporationIdCheck: {
          message1:
            'The controller has already been registered. Please check by searching in the machines.',
          message2:
            'If you cannot find the target controller in the list, please contact us from the URL below.',
        },
        earthbrainIdCheck: {
          message1: 'We apologize for the inconvenience,',
          message2:
            'but please contact us from the URL below with the following information.',
          message3: '-Controller serial number',
          message4: '-Name of the machine to be registered (optional)',
          message5: '-Company ID to be registered',
        },
        invalidSerialNumber: {
          message1: 'The controller is already in use by another company.',
          message2: 'If you would like to change it for other reasons, ',
          message3:
            'please contact us from the URL below with the following information.',
          message4: '-Controller serial number',
          message5: '-Name of the machine to be registered (optional)',
          message6: '-Company ID to be registered',
          message7: '-Reason for change',
        },
        lendingRetrofits: {
          message1: 'The controller is assigned.',
          message2:
            'Please reapply after the assignment ends at the assigning company.',
          message3:
            'If you have any questions, please contact us from the URL below.',
        },
        siteAttached: {
          message1: 'The controller is linked to a jobsite.',
          message2:
            'Please reapply after disconnect the machine from the jobsite.',
          message3:
            'If you have any questions, please contact us from the URL below.',
        },
        serialNumberExistError: {
          message1:
            'This is the serial number of a controller that does not exist. Please make sure the controller serial number you entered is correct.',
          message2:
            'If the problem persists, please contact us from the URL below.',
        },
        ntripShortage: {
          message1: 'We were unable to issue your Ntrip user ID and password.',
          message2: 'Please contact us at the URL below.',
        },
        serialnumberCheck: {
          message:
            'Please check the products with the following serial number(s)',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'This controller is already owned by your company.',
          message2: 'Please contact to the applicant company.',
        },
        userCorporation: {
          message1:
            'You cannot approve this request because you are already lending this controller.',
          message2: 'Please contact to the applicant company.',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `There is a machine in use on-site without a license.
        A license is required for use on-site. 
        If you have not purchased a license, please purchase one from the Smart Construction Marketplace.`,
        message2: `
        If you are renting from another company, please request a license for the machine from the rental source. 
        
        If you have any questions, please check the following support site.`,
      },
    },
    tootip: {
      retrofitShareFileList: 'Application Log',
      retrofitConfigList: 'Configuration Info',
      retrofitKitInfoList: 'Controller Info',
      retrofitAccuraciesList: 'Accuracy Check Result',
      retrofitCalibaList: 'Calibration Info',
      retrofitBasicSettingList: 'Basic Setting',
      retrofitErrorList: 'Error Details',
      retrofitTopographicSurveyList: 'Topographic Survey',
    },
    actions: {
      ok: 'OK',
      add: 'Add',
      create: 'Registration',
      yes: 'Yes',
      no: 'No',
      confirmation: 'Confirm',
      save: 'Save',
      cancel: 'Cancel',
      required: 'Required',
      addMachineToSite: 'Add Machine to Jobsite',
      addBucketToSite: 'Add',
      deleteFromSite: 'Disconnect from Jobsite',
      removeLicense: 'Disconnect from Machine',
      lendMachine: 'Assignment',
      transferMachine: 'Transfer',
      remoteSupport: 'Remote Support',
      lendBucket: 'Share',
      approval: 'Approval',
      connect: 'Connect',
      creationInstructions: 'Acquire Construction Result',
      csvDownload: 'Download',
      retrofitBundleCreate: 'Bulk Registration',
      termsAccept: 'Agree',
      termsDeny: 'Disagree',
      bulkRegistration: 'Bulk Update',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Compaction Items',
      addLanguage: 'Add Language',
    },
    message: {
      error: 'An error has occurred',
      confirm: 'Is it OK?',
      fileUpload: 'Drag and drop files here.',
      success: 'Registration has been completed',
      approved: 'Approval has been completed',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Do you want to delete %{title}?',
      bulkDeleteConfirmContent: 'Do you want to delete %{title}?',
      disconnectFromSite: 'Do you want to disconnect from the jobsite?',
      saveConfirmContent: 'Confirm',
      removeLicense: 'Do you want to disconnect from the machine?',
      task: {
        crossingMessage: 'The task area is intersected.',
        sameValueMessage: 'The same coordinates have been entered.',
        areaZeroMessage: 'There is no area for the task area.',
        invalidPolygonMessage: 'The same coordinates have been entered.',
      },
      geofence: {
        crossingMessage: 'The geofence area is intersected.',
        sameValueMessage: 'The same coordinates have been entered.',
        areaZeroMessage: 'There is no area for the geofence area.',
      },
      licenseError:
        'You do not have a license for Smart Construction Pilot(Web App).',
      retrofitsManage: {
        serialNumberMatchError: 'Serial Number does not match.',
        serialNumberInputError: 'Serial Number does not exist.',
        invalidAccess:
          'You are not authorized to view this page, return to the top page.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Serial Number does not match.',
        serialNumberInputError: 'Serial Number does not exist.',
        retrofitRentaled:
          'Company information cannot be updated while the controller is on assignment.',
        invalidCorporation:
          'Since your company is not a distributor, you cannot do proxy registration of controller.',
      },
    },
    validation: {
      startDate: 'Start Date',
      endDate: 'End Date',
      invalidDateFormat: 'The date format is invalid.',
      invalidDateTimeFormat: 'The date and time format is invalid.',
      duplicateProject: 'Project name is duplicated.',
      incorrectBeforeDate: '%{target} must be set a date before %{before}.',
      incorrectAfterDate: '%{target} must be set a date after %{after}.',
      incorrectPlanStartDate:
        'Scheduled start date must be before the scheduled end date.',
      incorrectPlanEndDate:
        'Scheduled end date must be a date after the scheduled start date.',
      incorrectActualStartDate:
        'Start date must be set a date before the end date.',
      incorrectActualEndDate:
        'End date must be set a date after the start date.',
      withActualStartDate: 'Please also enter the start date.',
      inputAvailableDate: 'The date that can be entered is from %{date}.',
      registrationRequired:
        'Please select either the assigned company or email address.',
      bothInput:
        'You can only enter either the assigned company or the email address.',
      supportMode: 'Please enter either a Company ID or Jobsite ID.',
      invalidBkFile: 'It is not a BK3 file.',
      invalidPdfFile: 'It is not a PDF file.',
      duplicatedBucketName: 'Bucket name is duplicated.',
      duplicatedTaskName: 'Task name is duplicated.',
      duplicateGeofenceName: 'Geofence name is duplicated.',
      duplicatedCompactionWorkAreaName: 'Duplicated compaction work area name',
      duplicatedCompactionLayerName: 'Duplicated compaction layer name',
      duplicated: {
        compactionMaterials: {
          message: 'Duplicated material name',
        },
        weathers: {
          message: 'Duplicated weather name',
        },
        operators: {
          message: 'Duplicated operator name',
        },
      },
      containInvalidString:
        'Invalid characters are included. (%{msg} is not allowed)',
      validHalfWidthAlphanumeric:
        'Only half-width alphanumeric characters are allowed.',
      validNumberFormat: 'Not a number.',
      validIntegerFormat: 'Not a integer.',
      validIntegerRangeFormat: 'Input is allowed from 0 to 2147483647.',
      validDuplicateLanguage: 'The same language is selected.',
      validBeforeDate:
        '%{target} must be set a date and time before %{before}.',
      validAfterDate: '%{target} must be set a date and time after %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Jobsite',
      fields: {
        name: 'Jobsite',
        corporationName: 'Company Name',
        status: 'Status',
        workPeriodStartDate: 'Start Date',
        workPeriodEndDate: 'End Date',
      },
      status: {
        completed: 'Completed',
        waiting: 'Before Construction Begins',
        working: 'Under Construction',
      },
    },
    retrofits: {
      name: 'Machine',
      fields: {
        information: 'Machine Info',
        rental: 'Assignment',
        sendBack: 'Send Back',
        corporationId: 'Company in Use',
        siteId: 'Jobsite',
        uuid: 'UUID',
        isError: 'Status',
        isOnline: 'Online',
        lastOnlineTime: 'Last Connection',
        basicInfoManufacturer: 'Manufacturer',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Product No.',
        basicInfoSerialNumber: 'Serial No.',
        controllerManufacturer: 'Manufacturer',
        controllerModel: 'Model',
        controllerFirmwareVersion: 'Firmware Ver.',
        gnssReceiverMainManufacturer: 'Manufacturer',
        gnssReceiverMainModel: 'Model',
        gnssReceiverMainFirmwareVersion: 'Firmware Ver.',
        gnssReceiverSubManufacturer: 'Manufacturer',
        gnssReceiverSubModel: 'Model',
        gnssReceiverSubFirmwareVersion: 'Firmware Ver.',
        machineInfoCompanyName: 'Makes',
        machineInfoMachineType: 'Machine Type',
        machineInfoMachineName: 'Machine Name',
        machineInfoMachineId: 'Machine ID',
        rentalStatus: 'Assignment',
        lastUpdated: 'Last Updated',
      },
      isError: {
        true: 'Error',
        false: 'Normal',
      },
      isOnline: {
        true: 'Online',
        false: 'Offline',
      },
      machineInfoMachineType: {
        excavator: 'Excavator',
        roadroller: 'Road Roller',
        dozer: 'Dozer',
        rigiddump: 'Rigid Dump',
        wheelloader: 'Wheel Loader',
        grader: 'Grader',
        automobile: 'Automobile',
      },
      licenseMachineType: {
        excavator: 'Excavator',
        roadroller: 'Road Roller',
        dozer: 'Dozer',
        rigiddump: 'Rigid Dump',
        wheelloader: 'Wheel Loader',
        grader: 'Grader',
        automobile: 'Automobile',
        excavator_license: 'Excavator : %{licenseGrantInfo}',
        roadroller_license: 'Road Roller : %{licenseGrantInfo}',
        dozer_license: 'Dozer : %{licenseGrantInfo}',
        rigiddump_license: 'Rigid Dump : %{licenseGrantInfo}',
        wheelloader_license: 'Wheel Loader : %{licenseGrantInfo}',
        grader_license: 'Grader : %{licenseGrantInfo}',
        automobile_license: 'Automobile : %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Transferring',
        sent: 'Transferred',
        failtosend: 'Transfer failed',
      },
      rentalStatus: {
        approved: 'Approved',
        unapproved: 'Unapproved',
        returned: 'Returned',
      },
    },
    retrofitRentals: {
      name: 'Assignment',
      fields: {
        id: 'ID',
        retrofitId: 'Machine ID',
        fromCorporationId: 'Assigning Company ID',
        fromCorporationName: 'Assigning Company',
        toCorporationId: 'Assigned Company',
        toCorporationName: 'Company in Use',
        retrofitRentalId: 'Assignment ID',
        isApproved: 'Status',
        isReturned: 'Returned',
        planStartDate: 'Scheduled Start Date',
        planEndDate: 'Scheduled End Date',
        actualStartDate: 'Start Date',
        actualEndDate: 'End Date',
        rentalUsers: 'Email',
        email: 'Email',
        rentalStatus: 'Status',
      },
      isApproved: {
        true: 'Approved',
        false: 'Unapproved',
      },
      rentalStatus: {
        approved: 'Approved',
        unapproved: 'Unapproved',
        returned: 'Returned',
      },
    },
    pendingRentals: {
      name: 'Waiting for Approval',
      fields: {
        fromCorporationName: 'Assigning Company',
        planStartDate: 'Scheduled Start Date',
        planEndDate: 'Scheduled End Date',
      },
    },
    pendingTransfers: {
      name: 'Waiting for Approval',
      fields: {
        fromCorporationName: 'Transferring Company',
        planDate: 'Scheduled Transfer Date',
        lastUpdated: 'Last Updated',
      },
    },
    pendingAlternateRegists: {
      name: 'Waiting for Approval',
      fields: {
        fromCorporationName: 'Current Owner',
        lastUpdated: 'Last Updated',
      },
    },
    assignments: {
      name: 'Transfer',
      fields: {
        id: 'ID',
        retrofitId: 'Machine ID',
        fromCorporationId: 'Transferring Company ID',
        fromCorporationName: 'Transferring Company',
        toCorporationId: 'Transferred Company',
        toCorporationName: 'Company',
        isApproved: 'Status',
        planDate: 'Scheduled Transfer Date',
        actualDate: 'Transfer Date',
        transferUsers: 'Email',
        email: 'Email',
        lastUpdated: 'Last Updated',
      },
      isApproved: {
        true: 'Approved',
        false: 'Unapproved',
      },
    },
    buckets: {
      name: 'Bucket',
      fields: {
        companyName: 'Company Name',
        siteId: 'Jobsite',
        name: 'Name',
        corporationId: 'Company in Use',
        bucketType: 'Type',
        bk3File: 'BK3 File',
        'bk3File.title': 'BK3 File',
        gltfFile: 'Bucket File',
        uploadedBy: 'Registered By',
        updatedBy: 'Updated By',
        fileStatus: 'Status',
        lastUpdated: 'Last Updated',
        uploadBk: 'Upload BK3',
      },
      bucketType: {
        slope: 'Slope',
        standard: 'Standard',
        tilt: 'Tilt',
      },
      uploadedBy: {
        admin: 'Admin',
        tablet: 'Tablet',
      },
      fileStatus: {
        converting: 'Converting',
        completed: 'Completed',
        error: 'Error',
      },
    },
    projects: {
      name: 'Project',
      fields: {
        name: 'Project Name',
        coordinate: 'Coordinate System',
        version: 'Version',
        status: 'Status',
        lastUpdated: 'Last Updated',
        regionId: 'Region',
        projectionId: 'Projection',
        geoidId: 'Geoid',
        datumId: 'Datum',
        file: 'File(s)',
        retrofitId: 'Target Machine',
      },
      status: {
        converted: 'Completed',
        waitforconvert: 'Converting',
        error: 'Error',
      },
    },
    extensionarms: {
      name: 'Extension Arm',
      fields: {
        extensionarmType: 'Type',
        name: 'Name',
        corporationId: 'Company in Use',
        corporationName: 'Company in Use',
        siteId: 'Jobsite',
        siteName: 'Jobsite',
        lastUpdated: 'Last Updated',
        extensionarmFile: 'Extension Arm File',
      },
      extensionarmType: {
        basic: 'Extension Arm',
        a: 'Type A',
        b: 'Type B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Share To',
        name: 'Name',
        extensionarmType: 'Type',
        extensionarmFile: {
          title: 'Extension Arm File',
        },
      },
    },
    corporations: {
      name: 'Company',
      fields: {
        name: 'Company in Use',
      },
    },
    retrofitShareFiles: {
      name: 'Application Log',
      fields: {
        name: 'File Name',
        fileTime: 'Received Date and Time',
        url: 'Download',
      },
    },
    retrofitConfigs: {
      name: 'Configuration Info',
      fields: {
        timeStamp: 'Received Date and Time',
        url: 'Download',
      },
    },
    retrofitKitInfos: {
      name: 'Controller Info',
      fields: {
        timeStamp: 'Received Date and Time',
        url: 'Download',
      },
    },
    retrofitAccuracies: {
      name: 'Accuracy Check Result',
      fields: {
        checkDateTime: 'Confirmation Date and Time',
        surveyPoint: 'Measured Point',
        positionN: 'Measured Value : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Offset Value : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Reference Point Name',
        referencePointN: 'Reference Point : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Unit of Length',
      },
    },
    retrofitCalibs: {
      name: 'Machine Calibration Info Details',
      fields: {
        timeStamp: 'Received Date and Time',
        url: 'Download',
        detailInfo: {
          createDateTime: 'Create date and time',
          updateDateTime: 'Update date and time',
          machineInfo: {
            companyName: 'Makes',
            machineType: 'Machine Type',
            machineName: 'Machine Name',
            machineId: 'Machine ID',
            drumConfiguration: 'Machine Configuration',
            guidanceMode: 'Guidance Mode',
          },
          machineGeometryInfo: {
            boomLength: 'Length of Boom',
            secondBoomLength: 'Length of 2nd Boom',
            armLength: 'Length of Arm',
            armTopPinToBucketSideLinkPinLength:
              'Distance b/w Arm Top and Bucket Side Link',
            boomTopPinToBucketSideLinkPinLength:
              'Distance b/w Boom Top and Bucket Side Link',
            bucketLink1Length: 'Distance b/w Bucket Side and Bucket Cyl',
            bucketLink2Length: 'Distance b/w Bucket Cyl and Bucket Link',
            bodyCenterToBoomFootPin: {
              x: 'Boom Foot Pin X w.r.t Body Center',
              y: 'Boom Foot Pin Y w.r.t Body Center',
              z: 'Boom Foot Pin Z w.r.t Body Center',
            },
            drumWidth: 'Width of Blade',
            length: 'Length between Shoes',
            shoeInterval: 'Interval length of Shoes',
            bladeWidthForDozer: 'Width of Blade for Dozer',
            distanceFromBodyToBlade: 'Distance from Body to Blade',
            frontToMainGnssAntenna:
              'Length from center of a straight line connecting the forward grounded end front to main gnss antenna X',
            frontToSubGnssAntenna:
              'Length from center of a straight line connecting the forward grounded end front to sub gnss antenna X',
            centerToMainGnssAntenna:
              'Y distance from center to main GNSS electric phase center',
            centerToSubGnssAntenna:
              'Y distance from center to sub GNSS electric phase center',
            groundToMainGnssAntenna:
              'Z distance from ground to main GNSS electric phase center',
            groundToSubGnssAntenna:
              'Z distance from ground to sub GNSS electric phase center',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Offset Angle of Boom IMU',
            secondBoomImuOffsetAngle: 'Offset Angle of 2nd Boom IMU',
            armImuOffsetAngle: 'Offset Angle of Arm IMU',
            bucketImuOffsetAngle: 'Offset Angle of Bucket IMU',
            bodyImuOffsetRollAngle: 'Offset Roll Angle of Body IMU',
            bodyImuOffsetPitchAngle: 'Offset Pitch Angle of Body IMU',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Main GNSS X w.r.t Body Center',
                y: 'Main GNSS Y w.r.t Body Center',
                z: 'Main GNSS Z w.r.t Body Center',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Sub GNSS X w.r.t Body Center',
                y: 'Sub GNSS Y w.r.t Body Center',
                z: 'Sub GNSS Z w.r.t Body Center',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Swing Boom Model',
            twoPieceBoom: '2 Piece Boom Model',
            minimalSwingRadius: 'Offset Boom Model',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Counter Weight Clearance',
            },
            bodyImu1: {
              roll: 'Roll of IMU measured value',
              pitch: 'Pitch of IMU measured value',
              yaw: 'Yaw of IMU measured value',
            },
            bodyImu2: {
              roll: 'Roll of IMU measured value',
              pitch: 'Pitch of IMU measured value',
              yaw: 'Yaw of IMU measured value',
            },
            frontLeft: {
              x: 'Surveyed value of front left X',
              y: 'Surveyed value of front left Y',
              z: 'Surveyed value of front left Z',
            },
            frontRight: {
              x: 'Surveyed value of front right X',
              y: 'Surveyed value of front right Y',
              z: 'Surveyed value of front right Z',
            },
            frontGroundingEnd: {
              x: 'Surveyed value of front gounding end X',
              y: 'Surveyed value of front gounding end Y',
              z: 'Surveyed value of front gounding end Z',
            },
            rearGroundingEnd: {
              x: 'Surveyed value of rear gounding end X',
              y: 'Surveyed value of rear gounding end Y',
              z: 'Surveyed value of rear gounding end Z',
            },
            mainGnssAntenna: {
              x: 'Surveyed value of main GNSS antenna X',
              y: 'Surveyed value of main GNSS antenna Y',
              z: 'Surveyed value of main GNSS antenna Z',
            },
            subGnssAntenna: {
              x: 'Surveyed value of sub GNSS antenna X',
              y: 'Surveyed value of sub GNSS antenna Y',
              z: 'Surveyed value of sub GNSS antenna Z',
            },
            prismPoleOffset: 'Prism pole henght',
            antennaElectricalCenterHeight:
              'Height from antenna electrical center',
          },
        },
      },
    },
    retrofitBasicSettings: {
      name: 'Basic Setting',
      fields: {
        timeStamp: 'Received Date and Time',
        url: 'Download',
      },
    },
    retrofitErrors: {
      name: 'Error Info',
      fields: {
        notifiedTime: 'Occurrence Date and Time',
        errorCodes: 'Error Code : Details',
      },
    },
    retrofitToposurveys: {
      name: 'Topographic Survey List',
      fields: {
        surveyDate: 'Surveyed Date and Time',
        projectName: 'Project Name',
        layerName: 'Layer',
        pointName: 'Point Name',
        surveyPoint: 'Measured Point',
        n: 'Measured Value : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Unit of Length',
      },
    },
    asbuilts: {
      name: 'Construction Results',
      fields: {
        execStartDate: 'Execution Start',
        execEndDate: 'Execution End',
        startPeriod: 'Acquisition Period From',
        endPeriod: 'Acquisition Period To',
        execStatus: 'Status',
        url: '',
        csvType: 'Data Format',
        createUser: 'create User',
      },
      execStatus: {
        complete: 'Completed',
        inprogress: 'Processing',
        error: 'Error',
        completewithoutdata: 'Completed(No Data)',
      },
    },
    retrofitBasicInfos: {
      name: 'Basic Info',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Distributor',
        retrofitRegionId: 'Region',
        corporationId: 'Company ID',
        corporationName: 'Company Name',
        basicInfoManufacturer: 'Manufacturer',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Product No.',
        basicInfoSerialNumber: 'Serial No.',
        lastUpdated: 'Last Updated',
        description: 'Description',
        ownerId: 'Company ID',
        ownerName: 'Company Name',
      },
      tag: {
        retrofitBasicInfosDetail: 'Basic Info',
        sendBack: 'Send Back',
      },
      sendBack: {
        fields: {
          site: 'Jobsite',
          corporation: 'Company in Use',
          ntripSettingInfo: 'Ntrip ID',
          toRetrofit: 'Alternative Controller',
          notificationEmailAddress: 'Email',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Bulk Registration',
      fields: {
        uploadFileName: 'File Name',
        execStartDate: 'Execution Start Date and Time',
        execEndDate: 'Execution End Date and Time',
        execStatus: 'Status',
        uploadFile: 'Registered File',
        resultFile: 'Registration Results',
      },
      execStatus: {
        complete: 'Completed',
        inprogress: 'Processing',
        error: 'Error',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'AdminCompanyBulkAction',
      fields: {
        uploadFileName: 'File Name',
        execStartDate: 'Execution Start Date and Time',
        execEndDate: 'Execution End Date and Time',
        execStatus: 'Status',
        uploadFile: 'Registered File',
        resultFile: 'Registration Results',
      },
      execStatus: {
        complete: 'Completed',
        inprogress: 'Processing',
        error: 'Error',
      },
    },
    tasks: {
      name: 'Task',
      fields: {
        name: 'Name',
        status: 'Status',
        description: 'Description',
        projectSourceLayerId: 'Layer',
        retrofitIds: 'Target Machine',
        expiredDate: 'Expiration Date ',
        createDate: 'Expiration Data',
        rectangle: 'Supported Range',
        lastUpdated: 'Last Updated',
      },
      status: {
        error: 'Error',
        converting: 'Converting',
        sending: 'Transferring',
        sent: 'Transferred',
        failtosend: 'Transfer failed',
      },
    },
    compactionMaterials: {
      name: 'Materials',
      fields: {
        name: 'Name',
      },
    },
    weathers: {
      name: 'Weathers',
      fields: {
        name: 'Name',
      },
    },
    operators: {
      name: 'Operators',
      fields: {
        name: 'Name',
      },
    },
    compactionWorkAreas: {
      name: 'Compaction Work Areas',
      fields: {
        name: 'Work Areas Name',
        blockSize: 'Block Size',
        description: 'Description',
      },
    },
    compactionLayers: {
      name: 'Compaction Layers',
      fields: {
        name: 'Name',
        thickness: 'Thickness',
        passCount: 'Pass Count',
        compactionMaterialId: 'Material',
        inUse: 'Status',
        description: 'Description',
      },
      inUse: {
        true: 'in Use',
        false: 'unused',
      },
    },
    gnssSettings: {
      name: 'Ntrip Setting',
      fields: {
        registCorporationName: 'Registered by',
        ownerCorporationId: 'Company ID',
        ownerCorporationName: 'Company Name',
        name: 'Name',
        url: 'Server URL',
        portnum: 'Port',
        userId: 'ID',
        password: 'Password',
        retrofitId: 'Target Machine',
        status: 'Status',
        updateDate: 'Last Updated',
      },
      status: {
        waitforsend: 'Converting',
        sending: 'Transferring',
        sent: 'Transferred',
        failtosend: 'Transfer failed',
      },
    },
    supportMode: {
      name: 'Support Mode',
      fields: {
        enabled: 'Enable Support Mode',
        corporationId: 'Company ID',
        corporationName: 'Company Name',
        siteId: 'Jobsite ID',
        siteName: 'Jobsite',
        latitude: 'Latitude',
        longitude: 'Longitude',
        workPeriodStartDate: 'Actual Construction Period (Start date)',
        workPeriodEndDate: 'Actual Construction Period (End date)',
        photoUrl: 'Site Picture URL',
        description: 'Description',
      },
    },
    userNotices: {
      name: 'Notification',
      noticeType: {
        emergencyimportant: 'Emergency/Important',
        emergency: 'Emergency',
        important: 'Important',
      },
      fields: {
        noticeType: 'Importance',
        noticeTypes: 'Importance',
        noticeCategory: 'Notification Type',
        title: 'Title',
        content: 'Contents',
        publicationStartDate: 'Start Date and Time(UTC)',
        publicationEndDate: 'End Date and Time(UTC)',
        file: 'Attachments',
        language: 'Language',
        emergency: 'Emergency',
        important: 'Important',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Version',
        compatibilityLevel: 'Compatibility Level',
        firmwareFile: 'Firmware File',
        firmwareFileCheckSum: 'Firmware Check Sum',
        firstInitializationFile: 'Initialization File 1',
        firstInitializationFileCheckSum: 'Initialization File 1 CheckSum',
        secondInitializationFile: 'Initialization File 2',
        secondInitializationFileCheckSum: 'Initialization File 2 CheckSum',
      },
    },
    bucketShares: {
      name: 'Bucket Sharing',
      fields: {
        fromBucketId: 'From Bucket Id',
        corporationId: 'Company ID',
        name: 'Name',
      },
    },
    extensionarmShare: {
      name: 'Extension Arm Sharing',
      fields: {
        name: 'Name',
      },
    },
    retrofitCorporationLicenses: {
      name: 'License',
      fields: {
        licenseId: 'License ID',
        licenseType: 'Type',
        issuedAt: 'Issue Date',
        expiresAt: 'Expiration Date ',
        retrofitId: 'Machine',
        comment: 'Description',
        licenseStatus: 'Status',
        licensePermission: 'License',
        productType: 'Product Name',
      },
      licenseStatus: {
        unlicense: 'Unallocated',
        disable: 'Invalid',
        enable: 'Valid',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Company Name',
        namePlates: 'Photo',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Unit of Length',
      },
    },
    geofences: {
      name: 'Geofence',
      fields: {
        type: 'Type',
        name: 'Name',
        dimension: 'Dimension',
        trigger: 'Trigger',
        valid: 'Valid',
        alertType: 'Alert Type',
        startDate: 'Start Date',
        endDate: 'End Date',
        transparency: 'Transparent',
        elevation: 'Elevation',
        height: 'Height',
        radius: 'Radius',
        lastUpdated: 'Last Updated',
        points: 'Supported Range',
        thickness: 'Thickness',
      },
      type: {
        line: 'Line',
        polygon: 'Polygon',
        circle: 'Circle',
        point: 'Point',
        wall: 'Wall',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Valid',
        false: 'Invalid',
      },
      alertType: {
        attention: 'Attention',
        notice: 'Notice',
        warning: 'Warning',
        caution: 'Caution',
        danger: 'Danger',
      },
      transparency: {
        true: 'Transparent',
        false: 'Opaque',
      },
    },
    geofenceAlerts: {
      name: 'Alerts',
      fields: {
        timeStamp: 'Detection Date and Time',
        geofenceName: 'Geofence Name',
        alertType: 'Alert Type',
        collisionPoint: 'Collision Point',
        retrofit: {
          machineInfoMachineName: 'Machine',
        },
      },
      alertType: {
        attention: 'Attention',
        notice: 'Notice',
        warning: 'Warning',
        caution: 'Caution',
        danger: 'Danger',
      },
    },
    ntrips: {
      name: 'Ntrip Management',
      fields: {
        name: 'User ID',
        ntripPass: 'Password',
        serial: 'Serial No.',
        customerCorporationId: 'Company ID',
        customerCorporationName: 'Company Name',
        ntrip: 'Status',
        numberOfExchanges: 'Number of Exchanges',
        id: 'ID',
        licenceId: 'License ID',
        dateOfRegistration: 'Date Of Registration',
        billingToCustomers: 'Billing To Customers',
        paymentToKomatsu: 'Payment To Komatsu',
        applicantId: 'Applicant ID',
        applicant: 'Applicant',
        requestCorporationId: 'Request Company ID',
        requestCorporationName: 'Request Company Name',
        isUsed: 'Is Used',
      },
      ntrip: {
        publish: 'Paid Out',
        stop: 'Stop',
        delete: 'Deleted',
      },
    },
    retrofitAlternateRegists: {
      name: 'Controller Proxy Registration',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'fromCompanyId',
        fromCorporationName: 'Controller Proxy Registration',
        toCorporationId: 'User',
        toCorporationName: 'Company Name',
        isApproved: 'Status',
        createDate: 'Application Date',
        actualDate: 'Approved Date',
        transferUsers: 'Email',
        email: 'Email',
        lastUpdated: 'Last Updated',
      },
      isApproved: {
        true: 'Approved',
        false: 'Unapproved',
      },
    },
    pendingApprovals: {
      name: 'Waiting for Approval',
      fields: {
        approvalType: 'Type',
        requestedBy: 'Applicant Company',
        lastUpdated: 'Last Updated',
      },
      approvalType: {
        assignment: 'Assignment',
        transfer: 'Transfer',
        agent: 'Controller Proxy Registration',
      },
    },
    retrofitSendbacks: {
      name: 'Send Back',
      fields: {
        fromRetrofitId: 'From Retrofit',
        toRetrofitId: 'Alternative Controller',
        notificationEmailAddress: 'Email',
        siteId: 'Jobsite',
        corporationId: 'Company ID',
        corporationName: 'Company in Use',
        ntripId: 'Ntrip Info',
        isComplete: 'Sendback Complete',
      },
    },
  },
};

export default message;
